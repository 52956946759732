import React from 'react';

const NoMatch = () => {
  return ( 
    <div>
      <p>NoMatch</p>
    </div>
   );
}
 
export default NoMatch;