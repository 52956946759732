import React from 'react'
import './Portfolio.css';

function Portfolio() {
    return (
        <div class="ledguide-full-page">
            <iframe width="830" height="740"
                src="https://docs.google.com/document/d/180WD4LogMjx8FmKcFc0dmlTEKJ1Xa7dIHlEhSLMOAxA/pub?embedded=true"></iframe>
        </div>
    )
}

export default Portfolio


